import {FilterRule} from "../../../components/Filters";
import {DateRange, defaultDateRange} from "../../../components/DateRangePicker";
import {AnalysisEntity, SelectOption} from "../../../../types";

export interface State {
    analysisEntity: AnalysisEntity,
    isReadyToFetchLeads: boolean
    isNotReadyToFetchLeadsReasons: Array<string>
    ruleGroup: {
        rules: Array<FilterRule>,
    }
    selectedColumns: Array<SelectOption>
    dateRange: DateRange,
}

export enum ActionType {
    SET_ANALYSIS_ENTITY,
    SET_DATE_RANGE,
    SET_IS_READY_TO_FETCH_LEADS,
    SET_RULE_GROUP,
    SET_SELECTED_COLUMNS,
}

export interface SetAnalysisEntityAction {
    type: ActionType.SET_ANALYSIS_ENTITY,
    payload: {
        entity: State['analysisEntity'],
    }
}

export interface SetDateRangeAction {
    type: ActionType.SET_DATE_RANGE,
    payload: {
        dateRange: State['dateRange'],
    }
}

export interface SetIsReadyToFetchLeadsAction {
    type: ActionType.SET_IS_READY_TO_FETCH_LEADS,
    payload: {
        isReadyToFetchLeads: State['isReadyToFetchLeads'],
        reasons: State['isNotReadyToFetchLeadsReasons'],
    }
}

export interface SetRuleGroupAction {
    type: ActionType.SET_RULE_GROUP,
    payload: {
        ruleGroup: State['ruleGroup'],
    }
}

export interface SetSelectedColumnsAction {
    type: ActionType.SET_SELECTED_COLUMNS,
    payload: {
        columns: State['selectedColumns'],
    }
}

export type Action =
    | SetAnalysisEntityAction
    | SetDateRangeAction
    | SetIsReadyToFetchLeadsAction
    | SetRuleGroupAction
    | SetSelectedColumnsAction


export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.SET_ANALYSIS_ENTITY:
            return {
                ...state,
                analysisEntity: action.payload.entity,
            };
        case ActionType.SET_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload.dateRange,
            };

        case ActionType.SET_IS_READY_TO_FETCH_LEADS:
            return {
                ...state,
                isReadyToFetchLeads: action.payload.isReadyToFetchLeads,
                isNotReadyToFetchLeadsReasons: action.payload.reasons,
            };
        case ActionType.SET_RULE_GROUP:
            return {
                ...state,
                ruleGroup: action.payload.ruleGroup,
            };
        case ActionType.SET_SELECTED_COLUMNS:
            return {
                ...state,
                selectedColumns: action.payload.columns,
            };
    }
}

export const initialState: State = {
    analysisEntity: AnalysisEntity.Leads,
    isReadyToFetchLeads: false,
    isNotReadyToFetchLeadsReasons: [],
    ruleGroup: {
        rules: [],
    },
    selectedColumns: [],
    dateRange: defaultDateRange
}
