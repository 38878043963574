import ConditionalDisplay from "../../../components/ConditionalDisplay";
import {IndefiniteProgressCircle} from "../../../components/IndefiniteProgressCircle";
import {ConditionalErrorMessage} from "../../../components/ConditionalErrorMessage";
import React, {useMemo} from "react";
import {Column} from "../../../../services/awesome-api";
import {AnalysisEntity, Option, SearchParam, SelectOption} from "../../../../types";
import DateRangePicker, {
    DateRange,
    DateRangePickerOnChange,
} from "../../../components/DateRangePicker";
import Filters, {
    FilterRule,
    FiltersPropsOnChange,
} from "../../../components/Filters";
import MultiSelect, {
    MultiSelectOnChange
} from "../../../components/MultiSelect";
import ContentSideBar from "../../../components/ContentSideBar";
import AnalysisEntityPicker, {AnalysisEntityPickerOnChange} from "../../../components/AnalysisEntityPicker";

export interface SidebarProps {
    isFetchingColumns: boolean,
    getColumnsError: unknown,
    columns: Array<Column> | undefined,
    selectedColumns: Array<SelectOption>,
    dateRangePickerOnChange: DateRangePickerOnChange,
    filtersPropsOnChange: FiltersPropsOnChange,
    columnsOnChange: MultiSelectOnChange,
    dateRange: DateRange,
    filterRules: Array<FilterRule>,
    analysisEntityPickerOnChange: AnalysisEntityPickerOnChange,
    analysisEntity: AnalysisEntity,
}

function Sidebar({
                     columns,
                     getColumnsError,
                     isFetchingColumns,
                     dateRangePickerOnChange,
                     filtersPropsOnChange,
                     columnsOnChange,
                     dateRange,
                     selectedColumns,
                     filterRules,
                     analysisEntityPickerOnChange,
                     analysisEntity,
                 }: SidebarProps) {
    const columnOptions: Array<SelectOption> = useMemo(() => {
        return (columns || [])
            .map((column) => ({
                value: column.name,
                label: `${column.name}`,
                data: column,
            }));
    }, [
        columns,
    ])

    const filterFields: Array<Option> = useMemo(() => {
        return (columns || [])
            .map((column) => {
                const name = column.name
                return {
                    name: name,
                    label: name,
                }
            });
    }, [
        columns,
    ])

    return (
        <ContentSideBar>
            <ConditionalDisplay isHidden={!isFetchingColumns}>
                <IndefiniteProgressCircle/>
            </ConditionalDisplay>

            <ConditionalErrorMessage
                error={getColumnsError}
                message={"Sorry, there was an error getting columns"}
            />

            <ConditionalDisplay isHidden={!columns === undefined}>
                <AnalysisEntityPicker
                    onChange={analysisEntityPickerOnChange}
                    value={analysisEntity}
                />

                <DateRangePicker
                    dateRange={dateRange}
                    onChange={dateRangePickerOnChange}
                />

                <MultiSelect
                    label="Columns"
                    searchParamName={SearchParam.Columns}
                    options={columnOptions}
                    value={selectedColumns}
                    onChange={columnsOnChange}
                />

                <Filters
                    value={filterRules}
                    fields={filterFields}
                    onChange={filtersPropsOnChange}
                />
            </ConditionalDisplay>
        </ContentSideBar>
    )
}

export default Sidebar;
