import {SearchParam, SelectOption, StringDict} from "../types";

export function getSearchParam(name: string): string | null {
    const location = window.location;
    const params = new URLSearchParams(location.search);
    const encodedValue = params.get(name);
    if (encodedValue !== null) {
        return decodeURIComponent(encodedValue);
    }
    return null
}

export function setSearchParam(name: string, value: string) {
    const location = window.location;
    const params = new URLSearchParams(location.search);
    params.set(name, encodeURIComponent(value));
    const newUrl = `${location.pathname}?${params.toString()}`
    window.history.pushState({}, '', newUrl);
}

export const STRING_ARRAY_DELIMITER = ",";

export function serializeStringArray(items: Array<string>): string {
    return items.join(STRING_ARRAY_DELIMITER);
}

export function setOptionArraySearchParam<T extends StringDict>(
    items: Array<T>,
    key: keyof T,
    name: SearchParam,
) {
    const value = serializeStringArray(items.map((item) => item[key]));
    setSearchParam(name, value)
}

export interface DeserializeOptionArrayParams {
    value: string
    columns: Set<string>
}

export function deserializeOptionArray({
                                           columns,
                                           value,
                                       }: DeserializeOptionArrayParams): Array<SelectOption> {
    const columnValues = value.split(STRING_ARRAY_DELIMITER);
    // We could convert seen to array but this will break the
    // original order
    const seen = new Set<string>();
    const result: Array<string> = []

    for (const value of columnValues) {
        if (!seen.has(value) && columns.has(value)) {
            seen.add(value)
            result.push(value)
        }
    }

    return result.map((item) => ({
        value: item,
        label: item,
    }))
}
