import {Flex, View} from "@adobe/react-spectrum";
import AwesomeAPIAnalyticsLogo from "../../components/Logo/AwesomeAPIAnalyticsLogo";
import IndefiniteProgressCircle from "../../components/IndefiniteProgressCircle";


function LoadingScreen() {
    return (
        <View
            minHeight="100vh"
            minWidth="100vw"
            UNSAFE_style={{
                backgroundColor: "var(--color-primary)"
            }}
        >
            <Flex
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
                minWidth="100vw"
            >
                <Flex
                    direction="column"
                    alignItems="center"
                >
                    <AwesomeAPIAnalyticsLogo/>
                    <IndefiniteProgressCircle/>
                </Flex>
            </Flex>
        </View>
    )
}

export default LoadingScreen;