import {Flex} from "@adobe/react-spectrum";

export interface ContentMainProps {
    children: JSX.Element | Array<JSX.Element> | any
}

function ContentMain({children}: ContentMainProps) {
    return (
        <Flex
            direction="column"
            width="100%"
            UNSAFE_className="unscrollable"
        >
            {children}
        </Flex>
    )
}

export default ContentMain;
