import {View} from "@adobe/react-spectrum";


export interface ConditionalErrorMessageProps {
    error: any
    message?: string,
}

export function ConditionalErrorMessage({
                                            error,
                                            message,
                                        }: ConditionalErrorMessageProps) {
    if (!error) {
        return <></>
    }

    if (message) {
        return <View>{message}</View>
    }

    return <View>{error.toString()}</View>
}
