import {ActionButton, Text, View} from "@adobe/react-spectrum";
import ConditionalDisplay from "../../../../components/ConditionalDisplay";
import {IndefiniteProgressCircle} from "../../../../components/IndefiniteProgressCircle";
import DownloadIcon from "@spectrum-icons/workflow/Download";
import React from "react";
import {State} from "../State";
import {FetchLeads} from "./types";
import ContentHeader from "../../../../components/ContentHeader";
import RunButton from "../../../../components/RunButton";
import {RowItem} from "../../../../../types";
import {downloadItems} from "../../../../../services/awesome-api/utils";

export interface HeaderProps {
    isFetchingFirstPageOfLeads: boolean
    state: State
    fetchLeads: FetchLeads
    tableData: Array<RowItem>
}

function Header({
                    fetchLeads,
                    isFetchingFirstPageOfLeads,
                    state,
                    tableData,
                }: HeaderProps) {
    return (
        <ContentHeader>
            <RunButton
                marginStart="size-50"
                isDisabled={!state.isReadyToFetchLeads}
                onPress={() => fetchLeads()}
            />

            <ConditionalDisplay isHidden={!isFetchingFirstPageOfLeads}>
                <IndefiniteProgressCircle/>
            </ConditionalDisplay>

            <View
                marginStart="auto"
            >
                <ActionButton
                    isDisabled={tableData.length === 0}
                    onPress={() => downloadItems(tableData)}
                >
                    <DownloadIcon/>
                    <Text>Download</Text>
                </ActionButton>
            </View>

        </ContentHeader>
    )
}

export default Header;
