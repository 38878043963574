import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
    ICognitoUserPoolData
} from 'amazon-cognito-identity-js';
import {AuthenticationError} from "./authentication_error";
import {COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID} from "../../constants";
import {useEffect, useState} from "react";

let userPool: CognitoUserPool;

export function createUserPool(params: ICognitoUserPoolData) {
    return new CognitoUserPool(params);
}

function getUserPool(): CognitoUserPool {
    if (userPool !== undefined) {
        return userPool;
    }
    userPool = createUserPool({
        UserPoolId: COGNITO_USER_POOL_ID,
        ClientId: COGNITO_CLIENT_ID,
    });
    return userPool;
}

function getSessionAsync(user: CognitoUser): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
        user.getSession((
            error: Error | null,
            session: CognitoUserSession | null,
        ) => {
            if (error) {
                return reject(error);
            }

            if (session) {
                return resolve(session);
            }

            reject('Impossible state.');
        })
    });
}

function promisedGetAttributes(user: CognitoUser): Promise<Array<CognitoUserAttribute>> {
    return new Promise((resolve, reject) => {
        user.getUserAttributes((
            error,
            session,
        ) => {
            if (error) {
                return reject(error);
            }

            if (session) {
                return resolve(session);
            }

            reject('Impossible state');
        })
    });
}

export type Attributes = Record<string, string>;

export interface UserAttributes extends Attributes {
    email: string,
}

function formatAttributes(attributes: Array<CognitoUserAttribute>): UserAttributes {
    return attributes.reduce((acc, attr) => ({
        ...acc,
        [attr.getName()]: attr.getValue()
    }), {} as UserAttributes);
}

export interface UserSession {
    user: CognitoUser,
    session: CognitoUserSession,
    attributes: UserAttributes,
}

export async function getSession(): Promise<UserSession | null> {
    const user = getUserPool().getCurrentUser();

    if (user === null) {
        return null;
    }

    return {
        user,
        session: await getSessionAsync(user),
        attributes: formatAttributes(await promisedGetAttributes(user)),
    };
}

function useGetSession() {
    const [session, setSession] = useState<UserSession | null>(null)

    useEffect(() => {
        getSession()
            .then((session) => {
                setSession(session);
            })
    }, [])

    return session
}

function useGetIdToken(): string | null {
    const [token, setToken] = useState<string | null>(null);
    const session = useGetSession()

    useEffect(() => {
        if (session !== null) {
            setToken(session.session.getIdToken().getJwtToken())
        }
    }, [session])

    return token;
}

function useGetUserEmail(): string | undefined {
    const session = useGetSession();
    return session?.attributes.email;
}

function authenticate(Username: string, Password: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username,
            Pool: getUserPool(),
        });

        const authenticationDetails = new AuthenticationDetails({
            Username,
            Password,
        });

        user.authenticateUser(authenticationDetails, {
            onSuccess: (data) => {
                resolve(data);
            },
            onFailure: (error) => {
                reject(error);
            },
            newPasswordRequired: (data) => {
                resolve(data);
            }
        });
    });
}

function logout() {
    getUserPool().getCurrentUser()?.signOut();
    window.location.assign('/');
}

function getUser(): CognitoUser | null {
    return getUserPool().getCurrentUser();
}

function changePassword(password: string, newPassword: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
        const session = await getSession();
        if (session === null) {
            return reject(new AuthenticationError());
        }
        const {user, attributes: {email}} = session;
        await authenticate(email, password);
        user.changePassword(
            password,
            newPassword,
            (error, result) => {
                if (error) {
                    return reject(error);
                }

                if (result === "SUCCESS") {
                    return resolve();
                }

                reject("Impossible state");
            });
    });
}

function sendVerificationCode(user: CognitoUser) {
    return new Promise(async (resolve, reject) => {
        user.forgotPassword({
            onSuccess: (data) => resolve(data),
            onFailure: (error) => reject(error),
        });
    });
}

function setNewPassword(
    user: CognitoUser,
    verificationCode: string,
    newPassword: string,
): Promise<void> {
    return new Promise(async (resolve, reject) => {
        user.confirmPassword(
            verificationCode,
            newPassword,
            {
                onSuccess: () => resolve(),
                onFailure: (error) => reject(error),
            });
    });
}

export const AuthContext = {
    getUserPool,
    getSession,
    useGetSession,
    authenticate,
    logout,
    changePassword,
    getUser,
    useGetUserEmail,
    useGetIdToken,
    forgotPasswordFlow: {
        sendVerificationCode,
        setNewPassword
    }
};
