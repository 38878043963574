import {Flex} from "@adobe/react-spectrum";

export interface ContentSideBarProps {
    children: JSX.Element | Array<JSX.Element>
}

function ContentSideBar({children}: ContentSideBarProps) {
    return (
        <Flex
            direction="column"
            gap="size-150"
            width="size-3600"
            minWidth="size-3600"
            height="100%"
            UNSAFE_style={{
                overflow: "auto",
                paddingRight: "8px",
            }}
            UNSAFE_className="custom-scroll-bar"
        >
            {children}
        </Flex>
    )
}

export default ContentSideBar;
