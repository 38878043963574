import {AnalyticsColumn} from "../../../../services/awesome-api";

export const DATE_RANGE_KEY = 'selection' as const;
export const DATE_RANGE_MIN_DATE = new Date("2021-05-19T00:00:00.000Z")

export const analysisColumns = [
    {
        Header: "time_stamp",
        accessor: AnalyticsColumn.TimeStamp,
    },
    {
        Header: "value",
        accessor: AnalyticsColumn.Value,
    },
];
