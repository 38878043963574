import {
    ActionButton,
    Content,
    Dialog,
    DialogTrigger,
    Divider,
    Heading,
    Text,
} from "@adobe/react-spectrum";
import UserIcon from "@spectrum-icons/workflow/User";
import SignOutButton from "./SignOutButton/SignOutButton";
import {useAuth} from "../../../../services/auth";


function ProfileViewTrigger() {
    const {
        logout,
        useGetUserEmail,
    } = useAuth();

    const email = useGetUserEmail() || "";

    return (
        <DialogTrigger
            type="popover"
        >
            <ActionButton
                UNSAFE_className={'white-label'}
                isQuiet
            >
                <UserIcon/>
                <Text>{email}</Text>
            </ActionButton>
            <Dialog>
                <Heading>{email}</Heading>
                <Divider/>
                <Content>
                    <SignOutButton
                        onPress={() => logout()}
                    />
                </Content>
            </Dialog>
        </DialogTrigger>
    )
}

export default ProfileViewTrigger;