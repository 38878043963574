import {View} from "@adobe/react-spectrum";
import ConditionalDisplay from "../../../../components/ConditionalDisplay";
import {ConditionalErrorMessage} from "../../../../components/ConditionalErrorMessage";
import React, {useMemo} from "react";
import {State} from "../State";
import {FormattedQueryResponse} from "../../../../../services/awesome-api";
import {useTable} from "react-table";
import Table from "../../../../components/Table/Table";
import {FetchLeads} from "./types";
import Header from "./Header";
import ContentMain from "../../../../components/ContentMain";
import NoData from "../../../../components/NoData";


export interface MainProps {
    getLeadsError: unknown
    isFetchingFirstPageOfLeads: boolean
    fetchLeads: FetchLeads
    leadResponse: FormattedQueryResponse | undefined
    state: State
}

function Main({
                  getLeadsError,
                  isFetchingFirstPageOfLeads,
                  fetchLeads,
                  leadResponse,
                  state,
              }: MainProps) {

    const tableData = useMemo(() => {
        if (leadResponse === undefined) {
            return [];
        }

        return leadResponse.items;
    }, [
        leadResponse,
    ])

    const tableColumns = useMemo(
        () => {
            return state.selectedColumns.map((column) => ({
                Header: column.label,
                accessor: column.value,
            }))
        },
        [
            state.selectedColumns,
        ]
    )

    const tableInstance = useTable({
        columns: tableColumns,
        data: tableData,
    })

    return (
        <ContentMain>
            <Header
                isFetchingFirstPageOfLeads={isFetchingFirstPageOfLeads}
                state={state}
                fetchLeads={fetchLeads}
                tableData={tableData}
            />

                {
                    state.isNotReadyToFetchLeadsReasons.map((message) => (
                        <View key={message}>{message}</View>
                    ))
                }

                <ConditionalErrorMessage
                    error={getLeadsError}
                    message={"Sorry, there was an error getting leads"}
                />

                <ConditionalDisplay
                    isHidden={
                        (leadResponse !== undefined)
                        && leadResponse.items.length > 0
                    }
                >
                    <NoData/>
                </ConditionalDisplay>

                <ConditionalDisplay
                    isHidden={
                        (leadResponse === undefined)
                        || leadResponse.items.length === 0
                    }
                >
                    <Table
                        tableInstance={tableInstance}
                    />
                </ConditionalDisplay>

        </ContentMain>
    )
}

export default Main;
