import {memo, useEffect, useLayoutEffect, useState} from "react";
import {View} from "@adobe/react-spectrum";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {LineSeries} from "@amcharts/amcharts4/charts";
import {RowItem} from "../../../../../types";

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

export interface Item {
    timeStamp: Date
    value: number
}

export interface LineChartProps {
    data: Array<RowItem>
    groupByColumns: Array<string>
}

type SeriesName = string
type SeriesData = Map<SeriesName, Array<Item>>

function nameFromDataPoint(
    dataPoint: RowItem,
    groupByColumns: LineChartProps['groupByColumns'],
) {
    if (groupByColumns.length === 0) {
        return "";
    }

    const nameComponents: Array<string | number> = [];

    for (const column of groupByColumns) {
        nameComponents.push(dataPoint[column])
    }

    return nameComponents.join(" ")
}

function LineChart({
                       data,
                       groupByColumns,
                   }: LineChartProps) {
    const [seriesData, setSeriesData] = useState<SeriesData>(new Map());

    useEffect(() => {
        let newSeriesData: SeriesData = new Map();
        for (const row of data) {
            const value = row['value'] as number;
            const timeStamp = new Date(row['ts']);
            const seriesName = nameFromDataPoint(row, groupByColumns);

            if (newSeriesData.has(seriesName)) {
                newSeriesData.get(seriesName)!.push({
                    value,
                    timeStamp,
                })
            } else {
                newSeriesData.set(seriesName, [{
                    value,
                    timeStamp,
                }])
            }
        }
        setSeriesData(newSeriesData);

    }, [
        data,
        groupByColumns,
    ])

    useLayoutEffect(() => {
        let chart = am4core.create("lineChart", am4charts.XYChart);
        chart.paddingRight = 20;

        if (groupByColumns.length > 0) {
            chart.legend = new am4charts.Legend();
        }

        chart.cursor = new am4charts.XYCursor();

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;
        valueAxis.renderer.labels.template.disabled = true;

        let scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX = scrollbarX;

        const dataSeries: Array<LineSeries> = []
        for (const [seriesName, data] of Array.from(seriesData.entries())) {
            const series = chart.series.push(new am4charts.LineSeries());
            dataSeries.push(series);
            series.data = data;

            series.dataFields.dateX = "timeStamp";
            series.dataFields.valueY = "value";
            series.tooltipText = groupByColumns.length > 0 ? "{name} - {valueY.value}" : "{valueY.value}";
            series.name = seriesName;

            series.strokeWidth = 2;

            const bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color("#fff");
            bullet.circle.strokeWidth = 2;

            scrollbarX.series.push(series);
        }

        const toggleSeries = chart.series.push(new am4charts.LineSeries());
        toggleSeries.name = "Toggle all";
        toggleSeries.dataFields.dateX = "timeStamp";
        toggleSeries.dataFields.valueY = "value";
        toggleSeries.events.on("hidden", function() {
            for (const series of dataSeries) {
                series.hide()
            }
        });

        toggleSeries.events.on("shown", function() {
            for (const series of dataSeries) {
                series.show()
            }
        });

        return () => {
            chart.dispose();
        };
    }, [
        seriesData,
        groupByColumns,
    ]);

    return (
        <View
            id="lineChart"
            width="100%"
            height="100%"
        >
        </View>
    )
}

export default memo(LineChart);
