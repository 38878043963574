import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider as AuthProvider} from "./services/auth";
import {defaultTheme, Provider} from "@adobe/react-spectrum";

ReactDOM.render(
    <Provider
        theme={defaultTheme}
        colorScheme="light"
    >
        <AuthProvider>
            <App/>
        </AuthProvider>
    </Provider>
    , document.getElementById('root')
);
