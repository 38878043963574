import React from "react";
import {ViewProps as _ViewProps} from "@react-types/view";
import {Option as _SelectOption} from "react-select/src/filters";
import {SpectrumProgressCircleProps as _SpectrumProgressCircleProps} from "@react-types/progress";
import {SpectrumButtonProps} from "@react-types/button";

export type StateErrorMessage = string | null;
export type SpectrumProgressCircleProps =
    _SpectrumProgressCircleProps
    & React.RefAttributes<import("@react-types/shared").DOMRefValue<HTMLDivElement>>;
export type ViewProps = _ViewProps & React.RefAttributes<import("@react-types/shared").DOMRefValue<HTMLElement>>;
export type ButtonProps = SpectrumButtonProps<"button"> & {
    ref?: React.Ref<import("@react-types/shared").FocusableRefValue<HTMLElement, HTMLElement>>
}

export interface Option {
    name: string,
    label: string,
}

export type SelectOption = Omit<_SelectOption, 'data'>;
export type RowItem = { [key: string]: string | number };
export type StringDict = { [key: string]: string };

export enum SearchParam {
    Columns = 'c',
    DataRange = 'd',
    AnalysisEntity = 'e',
    Rules = 'r',
    TimeInterval = 't',
    AnalysisViewType = 'v',
}

export type PickerOption = {
    id: string,
    name: string,
}

export enum TimeInterval {
    Day = "day",
    Month = "month",
    Year = "year",
}

export enum AnalysisEntity {
    Leads = 'leads',
}