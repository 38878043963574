export interface AwesomeLogoProps {
    letterFill?: string,
    emojiFill?: string,
}

function AwesomeAPILogo({
    letterFill = "#FFFFFF",
    emojiFill = "#FFEC00",
                     }: AwesomeLogoProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 488.49 63.45"
        >
            <g>
                <g
                    fill={letterFill}
                >
                    <path 
                          d="M17.9,27.47L31.8,25.4c3.22-0.46,4.25-2.07,4.25-4.02c0-4.02-3.1-7.36-9.54-7.36    c-6.67,0-10.34,4.25-10.8,9.19L2.15,20.34C3.07,11.5,11.23,1.73,26.4,1.73c17.93,0,24.59,10.11,24.59,21.49v27.81    c0,2.99,0.35,7.01,0.69,8.97H37.67c-0.35-1.5-0.57-4.6-0.57-6.78c-2.87,4.48-8.28,8.39-16.67,8.39C8.36,61.61,1,53.45,1,44.6    C1,34.48,8.47,28.85,17.9,27.47z M36.06,37.12V34.6L23.3,36.55c-3.91,0.57-7.01,2.76-7.01,7.13c0,3.33,2.41,6.55,7.35,6.55    C30.08,50.23,36.06,47.13,36.06,37.12z"/>
                    <path 
                          d="M111.33,3.45l12.18,36.44l10.46-36.44h15.17L131.45,60h-15.28l-13.22-38.62L89.96,60H74.44L56.39,3.45h16.09    l10.46,36.32l12.3-36.32H111.33z"/>
                    <path 
                          d="M206.15,43.79c-2.87,9.77-11.84,17.93-25.74,17.93c-15.52,0-29.19-11.15-29.19-30.23    c0-18.05,13.33-29.77,27.81-29.77c17.47,0,27.93,11.15,27.93,29.31c0,2.18-0.23,4.48-0.23,4.71h-40.46    c0.35,7.47,6.67,12.87,14.25,12.87c7.13,0,11.03-3.56,12.87-8.62L206.15,43.79z M191.9,25.29c-0.23-5.63-3.91-11.15-12.64-11.15    c-7.93,0-12.3,5.98-12.64,11.15H191.9z"/>
                    <path 
                          d="M226.05,41.38c0.34,4.48,3.68,8.62,10.34,8.62c5.06,0,7.47-2.64,7.47-5.63c0-2.53-1.72-4.6-6.09-5.52    l-7.47-1.72c-10.92-2.41-15.86-8.96-15.86-16.9c0-10.11,8.97-18.5,21.15-18.5c16.09,0,21.49,10.23,22.18,16.32l-12.76,2.87    c-0.46-3.33-2.87-7.59-9.31-7.59c-4.02,0-7.24,2.41-7.24,5.63c0,2.76,2.07,4.48,5.17,5.06l8.05,1.72    c11.15,2.3,16.78,9.08,16.78,17.36c0,9.19-7.13,18.62-21.95,18.62c-17.01,0-22.87-11.03-23.56-17.47L226.05,41.38z"/>
                    <path 
                          d="M334.37,60V3.45h14.6v6.9c3.1-5.52,10.34-8.51,16.55-8.51c7.7,0,13.91,3.33,16.79,9.43    c4.48-6.9,10.46-9.43,17.93-9.43c10.46,0,20.46,6.32,20.46,21.49V60h-14.83V26.44c0-6.09-2.98-10.69-10-10.69    c-6.55,0-10.46,5.06-10.46,11.15V60h-15.17V26.44c0-6.09-3.1-10.69-10-10.69c-6.67,0-10.58,4.94-10.58,11.15V60H334.37z"/>
                    <path 
                          d="M485.4,43.79c-2.87,9.77-11.84,17.93-25.75,17.93c-15.52,0-29.19-11.15-29.19-30.23    c0-18.05,13.33-29.77,27.81-29.77c17.47,0,27.93,11.15,27.93,29.31c0,2.18-0.23,4.48-0.23,4.71h-40.46    c0.34,7.47,6.66,12.87,14.25,12.87c7.12,0,11.03-3.56,12.87-8.62L485.4,43.79z M471.14,25.29c-0.23-5.63-3.91-11.15-12.64-11.15    c-7.93,0-12.29,5.98-12.64,11.15H471.14z"/>
                </g>
                <g>
                    <path fill={emojiFill}
                          d="M324.44,31.73c0,17.36-12.76,30-29.65,30c-16.9,0-29.65-12.64-29.65-30c0-17.47,12.76-30,29.65-30    C311.68,1.73,324.44,14.25,324.44,31.73z"/>
                    <g>
                        <path
                            d="M279.93,43.87l4.03-0.62c0.93-0.14,1.24-0.59,1.24-1.18c0-0.99-0.86-1.86-2.52-1.86c-1.83,0-2.83,1.24-2.93,2.52     l-4.55-0.93c0.21-2.45,2.48-5.69,7.51-5.69c5.55,0,7.59,3.1,7.59,6.62v8.38c0,1.34,0.17,2.47,0.21,2.65h-4.72     c-0.03-0.14-0.17-0.76-0.18-1.93c-0.89,1.45-2.55,2.41-4.82,2.41c-3.76,0-5.93-2.48-5.93-5.21     C274.86,46.01,277.1,44.29,279.93,43.87z M285.2,47.04v-0.76l-3.24,0.52c-1.1,0.17-1.93,0.69-1.93,1.9c0,0.9,0.59,1.76,2.04,1.76     C283.62,50.45,285.2,49.7,285.2,47.04z"/>
                        <path
                            d="M304.49,36.22c-2.45,0-4.31,1.07-5.03,2.17l0-1.76l-5.07,0v25.08c0.13,0,0.26,0.02,0.39,0.02c1.66,0,3.27-0.16,4.84-0.39     v-8.91c0.79,0.97,2.45,1.73,4.65,1.72c4.79,0,8.17-3.75,8.17-8.99C312.46,40.01,309.46,36.22,304.49,36.22z M303.43,49.46     c-2.04,0-3.86-1.45-3.86-4.28c0-2.86,1.82-4.25,3.86-4.24c2.07,0,3.89,1.38,3.89,4.24C307.32,48.04,305.49,49.46,303.43,49.46z"/>
                        <g>
                            <path d="M320.89,46.5v-9.87l-5.24,0V53.5C317.72,51.47,319.5,49.13,320.89,46.5z"/>
                            <path
                                d="M318.23,34.46c1.72,0,3.1-1.38,3.1-3.07c0-1.69-1.38-3.07-3.1-3.07c-1.66,0-3.04,1.38-3.03,3.07      C315.2,33.08,316.58,34.46,318.23,34.46z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default AwesomeAPILogo;
