import {Button, Text} from "@adobe/react-spectrum";
import SendIcon from "@spectrum-icons/workflow/Send";
import React from "react";
import {ButtonProps} from "../../types";

function RunButton(props: Omit<ButtonProps, "variant">) {
    return (
        <Button
            variant="primary"
            {...props}
        >
            <SendIcon/>
            <Text>Run</Text>
        </Button>
    )
}

export default RunButton;
