import {Item, Picker, View} from "@adobe/react-spectrum";
import {memo, useEffect} from "react";
import {SpectrumPickerProps} from "@react-types/select";
import {getSearchParam, setSearchParam} from "../../../../../utils/url_search";
import {PickerOption, SearchParam} from "../../../../../types";

export enum AnalysisViewType {
    Table = "table",
    LineChart = "line chart",
    StackedColumnChart = "stacked column chart",
}

export const viewTypes: Array<PickerOption> = Object.values(AnalysisViewType)
    .map((interval) => ({
        id: interval,
        name: interval,
    }));

export type AnalysisViewTypePickerOnChange = (viewType: AnalysisViewType) => void

export interface IntervalPickerProps extends Omit<SpectrumPickerProps<PickerOption>, "children"> {
    onChange: AnalysisViewTypePickerOnChange
    value: AnalysisViewType,
}

function isAnalysisViewType(value: string): value is AnalysisViewType {
    const optionNames: Set<string> = new Set(Object.values(AnalysisViewType));
    return optionNames.has(value)
}

function AnalysisViewTypePicker({
                                    onChange,
                                    value,
                                    ...props
                                }: IntervalPickerProps) {

    useEffect(() => {
        const optionString = getSearchParam(SearchParam.AnalysisViewType);
        if (
            (optionString !== null)
            && (isAnalysisViewType(optionString))
        ) {
            onChange(optionString)
        } else {
            setSearchParam(SearchParam.AnalysisViewType, AnalysisViewType.Table)
        }
    }, [
        onChange,
    ])


    return (
        <View>
            <Picker
                labelPosition="side"
                label="View"
                items={viewTypes}
                selectedKey={value}
                onSelectionChange={(value) => {
                    const viewType = value as AnalysisViewType;
                    onChange(viewType);
                    setSearchParam(SearchParam.AnalysisViewType, viewType)
                }}
                {...props}
            >
                {(item) => <Item>{item.name}</Item>}
            </Picker>
        </View>
    )
}

export default memo(AnalysisViewTypePicker);
