import {Flex, View,} from "@adobe/react-spectrum";
import AwesomeAPIAnalyticsLogo from "../../components/Logo/AwesomeAPIAnalyticsLogo";
import ProfileViewTrigger from "./ProfileViewTrigger/ProfileViewTrigger";

function Header() {
    return (
        <View
            UNSAFE_style={{
                backgroundColor: 'var(--color-primary)',
            }}
            gridArea="header"
            paddingX="size-175"
        >
            <Flex
                alignItems="center"
                height="100%"
            >
                <AwesomeAPIAnalyticsLogo/>
                <View
                    marginStart="auto"
                >
                    <ProfileViewTrigger />
                </View>
            </Flex>
        </View>
    )
}

export default Header;
