import {View} from "@adobe/react-spectrum";
import ConditionalDisplay from "../../../../components/ConditionalDisplay";
import {ConditionalErrorMessage} from "../../../../components/ConditionalErrorMessage";
import React, {useMemo} from "react";
import {State} from "../State";
import {FormattedQueryResponse} from "../../../../../services/awesome-api";
import {useTable} from "react-table";
import {FetchReport} from "./types";
import Header from "./Header";
import ContentMain from "../../../../components/ContentMain";
import Table from "../../../../components/Table/Table";
import {analysisColumns} from "../constants";
import NoData from "../../../../components/NoData";
import {AnalysisViewType, AnalysisViewTypePickerOnChange} from "./AnalysisViewTypePicker";
import LineChart from "./LineChart";
import StackedColumnChart from "./StackedColumnChart";


export interface MainProps {
    fetchReportError: unknown
    isFetchingReport: boolean
    fetchReport: FetchReport
    fetchReportResponse: FormattedQueryResponse | undefined
    state: State
    analysisViewTypePickerOnChange: AnalysisViewTypePickerOnChange
}

function Main({
                  fetchReportError,
                  isFetchingReport,
                  fetchReport,
                  fetchReportResponse,
                  state,
                  analysisViewTypePickerOnChange,
              }: MainProps) {

    const tableData = useMemo(() => {
        if (fetchReportResponse === undefined) {
            return [];
        }

        return fetchReportResponse.items;
    }, [
        fetchReportResponse,
    ])

    const tableColumns = useMemo(
        () => {
            return state.selectedColumns.map((column) => ({
                Header: column.label,
                accessor: column.value,
            })).concat(...analysisColumns);
        },
        [
            state.selectedColumns,
        ]
    )

    const tableInstance = useTable({
        columns: tableColumns,
        data: tableData,
    })

    return (
        <ContentMain>
            <Header
                isReadyToFetchReport={state.isReadyToFetchReport}
                isFetchingReport={isFetchingReport}
                fetchReport={fetchReport}
                tableData={tableData}
                analysisViewType={state.analysisViewType}
                analysisViewTypePickerOnChange={analysisViewTypePickerOnChange}
            />

            {
                state.isNotReadyToFetchLeadsReasons.map((message) => (
                    <View key={message}>{message}</View>
                ))
            }

            <ConditionalErrorMessage
                error={fetchReportError}
                message={"Sorry, there was an error running analysis"}
            />

            <ConditionalDisplay
                isHidden={
                    (fetchReportResponse !== undefined)
                    && fetchReportResponse.items.length > 0
                }
            >
                <NoData/>
            </ConditionalDisplay>

            <ConditionalDisplay
                isHidden={
                    (fetchReportResponse === undefined)
                    || (fetchReportResponse.items.length === 0)
                }
            >

                <ConditionalDisplay
                    isHidden={state.analysisViewType !== AnalysisViewType.Table}
                >
                    <Table
                        tableInstance={tableInstance}
                    />
                </ConditionalDisplay>

                <ConditionalDisplay
                    isHidden={state.analysisViewType !== AnalysisViewType.LineChart}
                >
                    <LineChart
                        data={tableData}
                        groupByColumns={state.selectedColumns.map((item) => item.value)}
                    />
                </ConditionalDisplay>

                <ConditionalDisplay
                    isHidden={state.analysisViewType !== AnalysisViewType.StackedColumnChart}
                >
                    <StackedColumnChart
                        data={tableData}
                        groupByColumns={state.selectedColumns.map((item) => item.value)}
                    />
                </ConditionalDisplay>


            </ConditionalDisplay>

        </ContentMain>
    )
}

export default Main;
