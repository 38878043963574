import {ProgressCircle} from "@adobe/react-spectrum";
import {SpectrumProgressCircleProps} from "../../types";

export type IndefiniteProgressCircleProps = SpectrumProgressCircleProps;

export function IndefiniteProgressCircle(props: IndefiniteProgressCircleProps) {
    return <ProgressCircle
        aria-label="Loading…"
        isIndeterminate
        {...props}
    />
}

export default IndefiniteProgressCircle;
