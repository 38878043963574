import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import {useEffect, useState} from 'react';
import {Grid, View} from "@adobe/react-spectrum";
import {BrowserRouter as Router} from "react-router-dom";
import Header from "./UI/shell/Header/Header";
import Sidebar from "./UI/shell/Sidebar/Sidebar";
import Content from "./UI/shell/Content/Content";
import {QueryClient, QueryClientProvider} from "react-query";
import {useAuth} from "./services/auth";
import {UserSession} from "./services/auth/auth_context";
import LoadingScreen from "./UI/screens/standalone/LoadingScreen";
import AuthScreen from "./UI/screens/standalone/AuthScreen/AuthScreen";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
});

function App() {
    const {getSession} = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [session, setSession] = useState<UserSession | null>(null);

    useEffect(() => {
        getSession()
            .then((session) => {
                if (session !== null) {
                    setSession(session);
                }
            })
            .catch((error) => {
                setError(error.toString());
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getSession]);

    if (loading) {
        return <LoadingScreen/>
    }

    if (!loading && error !== null) {
        return <div>Sorry there was an error</div>
    }

    if (!loading && session === null) {
        return <AuthScreen/>
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <View
                    UNSAFE_className="unscrollable"
                    height="100vh"
                >
                    <Grid
                        areas={[
                            'header  header',
                            'sidebar content'
                        ]}
                        columns={["size-2000", '1fr']}
                        rows={["size-600", '1fr']}
                        UNSAFE_className="unscrollable"
                    >
                        <Header/>
                        <Sidebar/>
                        <Content/>
                    </Grid>
                </View>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
