import {DIGITS_RE, EMAIL_RE, LOWERCASE_RE, UPPERCASE_RE} from "../constants";

export function isRequired(fieldName: string) {
    return (value: string) => {
        return value.trim() !== "" || `${fieldName} is required`
    }
}

export function isValidEmail(value: string) {
    return EMAIL_RE.test(value) || "Email must be valid"
}

export function hasLowercase(value: string) {
    return LOWERCASE_RE.test(value) || "Password must have lowercase letters"
}

export function hasUppercase(value: string) {
    return UPPERCASE_RE.test(value) || "Password must have uppercase letters"
}

export function hasDigits(value: string) {
    return DIGITS_RE.test(value) || "Password must have digits"
}

export function minimumLength(fieldName: string, minimumLength: number) {
    return (value: string) => {
        return value.length >= minimumLength || `Minimum ${fieldName} length is ${minimumLength} characters`
    }
}

export const passwordRules = {
    validate: {
        required: isRequired("Password"),
        uppercase: hasUppercase,
        lowercase: hasLowercase,
        digits: hasDigits,
        minimumLength: minimumLength("password", 8)
    }
};