import {ErrorMessage as HookErrorMessage} from "@hookform/error-message";
import {View} from "@adobe/react-spectrum";
import {CSSProperties} from "react";

export interface ErrorMessage {
    message: string
    style?: CSSProperties
}

export function ErrorMessageWrapper({
                                        message,
                                        style = {},
                                    }: ErrorMessage) {
    return (
        <View UNSAFE_style={{
            color: "var(--spectrum-global-color-red-700)",
            ...style,
        }}>
            {message}
        </View>
    )
}

export interface ErrorMessagesProps {
    errors: any
    name: string
    style?: CSSProperties
}

export function FormErrorMessages({
                                      errors,
                                      name,
                                      style = {},
                                  }: ErrorMessagesProps) {
    return (
        <HookErrorMessage
            errors={errors}
            name={name}
            render={({messages, message}: any) => {
                if (message !== undefined) {
                    return (
                        <ErrorMessageWrapper
                            message={message}
                            style={style}
                        />
                    )
                }

                if (messages !== undefined) {
                    Object.entries(messages).map(([type, message]) => (
                        <ErrorMessageWrapper
                            key={type}
                            // @ts-ignore
                            message={message?.toString() || ""}
                            style={style}
                        />
                    ))
                }

                return null;
            }}
        />
    )
}

export default FormErrorMessages;