import {memo, useEffect, useLayoutEffect, useState} from "react";
import {View} from "@adobe/react-spectrum";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {ColumnSeries} from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {RowItem} from "../../../../../types";

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

export interface Item {
    timeStamp: Date
    value: number
}

export interface StackedColumnChartProps {
    data: Array<RowItem>
    groupByColumns: Array<string>
}

type SeriesName = string
type SeriesData = Map<SeriesName, Array<Item>>

function nameFromDataPoint(
    dataPoint: RowItem,
    groupByColumns: StackedColumnChartProps['groupByColumns'],
) {
    if (groupByColumns.length === 0) {
        return "";
    }

    const nameComponents: Array<string | number> = [];

    for (const column of groupByColumns) {
        nameComponents.push(dataPoint[column])
    }

    return nameComponents.join(" ")
}

function StackedColumnChart({
                                data,
                                groupByColumns,
                            }: StackedColumnChartProps) {
    const [seriesData, setSeriesData] = useState<SeriesData>(new Map());
    const [timeStamps, setTimeStamps] = useState<Array<Date>>([]);

    useEffect(() => {
        const newSeriesData: SeriesData = new Map();
        const newTimeStampStrings: Set<string> = new Set();

        for (const row of data) {
            const value = row['value'] as number;
            newTimeStampStrings.add(row['ts'] as string);
            const timeStamp = new Date(row['ts']);
            const seriesName = nameFromDataPoint(row, groupByColumns);

            if (newSeriesData.has(seriesName)) {
                newSeriesData.get(seriesName)!.push({
                    value,
                    timeStamp,
                })
            } else {
                newSeriesData.set(seriesName, [{
                    value,
                    timeStamp,
                }])
            }
        }
        setSeriesData(newSeriesData);
        const newTimeStamps = Array.from(newTimeStampStrings)
            .sort()
            .map((item) => new Date(item));
        setTimeStamps(newTimeStamps);
    }, [
        data,
        groupByColumns,
    ])

    useLayoutEffect(() => {
        const chart = am4core.create("stackedColumnChart", am4charts.XYChart);
        chart.paddingRight = 20;

        if (groupByColumns.length > 0) {
            chart.legend = new am4charts.Legend();
        }

        chart.cursor = new am4charts.XYCursor();

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        let scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX = scrollbarX;

        const dataSeries: Array<ColumnSeries> = []

        for (const [seriesName, originalData] of Array.from(seriesData.entries())) {
            const mappedData = new Map<number, Item>();

            for (const item of originalData) {
                mappedData.set(item.timeStamp.valueOf(), item);
            }

            const data: Array<Item> = [];

            for (const ts of timeStamps) {
                data.push(mappedData.get(ts.valueOf()) || {
                    value: 0,
                    timeStamp: ts,
                })
            }


            const series = chart.series.push(new am4charts.ColumnSeries());
            dataSeries.push(series);
            series.data = data;

            series.dataFields.dateX = "timeStamp";
            series.dataFields.valueY = "value";

            series.stacked = true;

            series.columns.template.width = am4core.percent(60);
            series.tooltipText = groupByColumns.length > 0 ? "{name} - {valueY.value}" : "{valueY.value}";
            series.name = seriesName;

            // Add label
            const labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = "{valueY}";
            labelBullet.locationY = 0.5;
            labelBullet.label.hideOversized = true;
            scrollbarX.series.push(series);
        }

        const toggleSeries = chart.series.push(new am4charts.ColumnSeries());
        toggleSeries.name = "Toggle all";
        toggleSeries.dataFields.dateX = "timeStamp";
        toggleSeries.dataFields.valueY = "value";
        toggleSeries.events.on("hidden", function () {
            for (const series of dataSeries) {
                series.hide()
            }
        });

        toggleSeries.events.on("shown", function () {
            for (const series of dataSeries) {
                series.show()
            }
        });

        return () => {
            chart.dispose();
        };
    }, [
        timeStamps,
        seriesData,
        groupByColumns,
    ]);

    return (
        <View
            id="stackedColumnChart"
            width="100%"
            height="100%"
        >
        </View>
    )
}

export default memo(StackedColumnChart);
