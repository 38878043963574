import {ActionButton, Flex, Heading, Item, Picker, TextField, View} from "@adobe/react-spectrum";
import {memo, useEffect} from "react";
import Select from "react-select";
import {v4 as generateUUID} from "uuid"
import CloseIcon from '@spectrum-icons/workflow/Close';
import ConditionalDisplay from "./ConditionalDisplay";
import {Option, SearchParam} from "../../types";
import {getSearchParam, setSearchParam} from "../../utils/url_search";

export interface FilterRule {
    id: string,
    field: Option,
    operator: string,
    expression: string,
}

const RULE_DELIMITER = ";";
const RULE_ELEMENT_DELIMITER = ",";
const RULE_COUNT_LIMIT = 5;

export function setRulesSearchParam(rules: Array<FilterRule>) {
    const value = serializeRuleArray(rules);
    setSearchParam(SearchParam.Rules, value)
}

export function serializeRule(rule: FilterRule): string {
    return [
        rule.id,
        rule.field.name,
        rule.operator,
        rule.expression,
    ].join(RULE_ELEMENT_DELIMITER)
}

export function serializeRuleArray(rules: Array<FilterRule>): string {
    return rules.map(serializeRule).join(RULE_DELIMITER)
}


export interface DeserializeRuleParams {
    value: string
    operators: Set<string>
    fields: Set<string>
}


export function deserializeRule({
                                    fields,
                                    operators,
                                    value,
                                }: DeserializeRuleParams): FilterRule | null {
    const [id, field, operator, expression] = value.split(RULE_ELEMENT_DELIMITER);
    if (
        (id === undefined)
        || (field === undefined)
        || (operator === undefined)
        || (expression === undefined)
        || (!operators.has(operator))
        || (!fields.has(field))
    ) {
        return null
    }

    return {
        id,
        field: {
            label: field,
            name: field,
        },
        expression,
        operator,
    }
}

/**
 * Returns only successfully deserialized rules. Excludes the rest
 */
export function deserializeRuleArray(params: DeserializeRuleParams): Array<FilterRule> {
    const ruleStrings = params.value.split(RULE_DELIMITER);

    const seen = new Set<string>();
    const result: Array<FilterRule> = [];
    for (const value of ruleStrings) {
        if (seen.size > RULE_COUNT_LIMIT) {
            break;
        }

        const rule = deserializeRule({
            value,
            fields: params.fields,
            operators: params.operators,
        })
        if (rule && !seen.has(rule.id)) {
            seen.add(rule.id);
            result.push(rule);
        }
    }

    return result;
}

export type FilterPropsOnChange = (value: FilterRule) => void;

export interface FilterProps {
    value: FilterRule,
    onChange: FilterPropsOnChange,
    onRemove: () => void,
}

export interface FilterGroup {
    rules: Array<FilterRule>,
}

export type FiltersPropsOnChange = (value: Array<FilterRule>) => void;


export interface FiltersProps {
    fields: Array<Option>,
    onChange: FiltersPropsOnChange,
    value: Array<FilterRule>,
}

export const operators: Array<string> = [
    "exactly matches",
    "contains",
    "begins with",
    "ends with",
    "does not exactly match",
    "does not contain",
    "does not begin with",
    "does not end with",
]

const operatorOptions: Array<Option> = operators.map((name) => ({name, label: name}))

function Filter({
                    onChange,
                    onRemove,
                    value,
                }: FilterProps) {
    return (
        <View
            borderColor="transparent"
            borderRadius="regular"
            borderWidth="thick"
            padding="size-50"
            width="100%"
            UNSAFE_style={{
                backgroundColor: "#EAEAEA"
            }}
        >
            <Flex
                direction="column"
                gap="size-50"
                width="100%"
            >
                <Flex
                    alignItems="center"
                >
                    <View>{value.field.label}</View>
                    <ActionButton
                        aria-label="close"
                        onPress={onRemove}
                        marginStart="auto"
                        isQuiet
                    >
                        <CloseIcon/>
                    </ActionButton>
                </Flex>

                <Picker
                    width="100%"
                    aria-label={"operator"}
                    items={operatorOptions}
                    selectedKey={value.operator}
                    onSelectionChange={(operator) => {
                        onChange({
                            ...value,
                            operator: operator as string,
                        })
                    }}
                >
                    {(item) => <Item key={item.name}>{item.label}</Item>}
                </Picker>
                <TextField
                    width="100%"
                    aria-label="filter expression"
                    value={value.expression}
                    onChange={(expression) => {
                        onChange({
                            ...value,
                            expression,
                        })
                    }}
                />
            </Flex>
        </View>
    )
}

function Filters({
                     fields,
                     onChange,
                     value,
                 }: FiltersProps) {

    useEffect(() => {
        if (fields.length === 0) {
            return
        }

        const columnSet = new Set(fields.map((column) => column.name))
        const operatorSet = new Set(operators)

        const rulesString = getSearchParam(SearchParam.Rules);
        if (rulesString !== null) {
            const rules = deserializeRuleArray({
                value: rulesString,
                fields: columnSet,
                operators: operatorSet,
            });
            onChange(rules);
            setRulesSearchParam(rules)
        }
    }, [
        fields,
        onChange,
    ])


    return (
        <View>
            <Heading level={4}>Filters</Heading>

            <ConditionalDisplay isHidden={value.length >= RULE_COUNT_LIMIT}>
                <View
                    paddingX="size-50"
                >
                    <Select
                        placeholder={<span>Add filter...</span>}
                        options={fields}
                        value={null}
                        onChange={(field) => {
                            if (!field) {
                                return
                            }

                            const newValue: FiltersProps["value"] = [
                                {
                                    id: generateUUID(),
                                    field: field as Option,
                                    operator: operators[0],
                                    expression: "",
                                },
                                ...value,
                            ]

                            onChange(newValue);
                            setRulesSearchParam(newValue)
                        }}
                    />
                </View>
            </ConditionalDisplay>

            <Flex
                direction="column"
                gap="size-150"
                marginTop="size-150"
            >
                {
                    value.map((item, index) => (
                        <Filter
                            key={item.id}
                            value={item}
                            onChange={(rule) => {
                                const newValue: FiltersProps["value"] = [...value];
                                newValue[index] = rule;
                                onChange(newValue);
                                setRulesSearchParam(newValue)
                            }}
                            onRemove={() => {
                                const newValue: FiltersProps["value"] = [
                                    ...value.slice(0, index),
                                    ...value.slice(index + 1),
                                ];
                                onChange(newValue);
                                setRulesSearchParam(newValue)
                            }}
                        />
                    ))
                }
            </Flex>
        </View>
    )
}

export default memo(Filters);