import {Flex, View} from "@adobe/react-spectrum"
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AwesomeAPIAnalyticsLogo from "../../../components/Logo/AwesomeAPIAnalyticsLogo";
import {useMediaQuery} from "react-responsive";
import LogInForm from "./LogInForm";
import ForgottenPasswordForm from "./ForgottenPasswordForm";


function AuthScreen() {

    const isNarrowScreen = useMediaQuery({
        query: '(max-width: 870px)'
    });

    return (
        <Router>
            <View
                height="100vh"
                UNSAFE_style={{
                    background: `linear-gradient(
                                                    37deg,
                                                    rgba(2,160,228,1) 0%,
                                                    rgba(0,159,227,1) 35%,
                                                    rgb(52 187 245) 100%
                                                )`,
                }}
            >
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <Flex
                        flexBasis={isNarrowScreen ? 350 : 700}
                        marginX="size-150"
                    >
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            direction={isNarrowScreen ? "column": "row"}
                            width="100%"
                        >
                            <AwesomeAPIAnalyticsLogo
                                marginX={isNarrowScreen ? "size-0" : "size-500"}
                                marginY={isNarrowScreen ? "size-250" : "size-0"}
                                width={isNarrowScreen ? "100%" : "size-4600"}
                            />

                            <View
                                width="100%"
                            >
                                <Switch>
                                    <Route path="/forgotten-password">
                                        <ForgottenPasswordForm/>
                                    </Route>
                                    <Route path="/">
                                        <LogInForm/>
                                    </Route>
                                </Switch>
                            </View>
                        </Flex>
                    </Flex>
                </Flex>
            </View>
        </Router>
    );
}

export default AuthScreen;
