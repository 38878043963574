import {RowItem} from "../../types";
import {ColumnValues} from "./index";

export function convertArraysToObjects<T extends RowItem = RowItem>(
    items: ColumnValues,
    total: number,
    inputColumns?: Array<string>
): Array<T> {
    const result: Array<T> = []
    const columns = inputColumns || Object.keys(items)

    for (let i = 0; i < total; i++) {
        result.push({} as T)

        for (const column of columns) {
            // @ts-ignore
            result[i][column] = items[column][i]
        }
    }

    return result;
}

export function convertItemsToCsv(items: Array<RowItem>): string {
    const rows: Array<string> = [];
    const columns = Object.keys(items[0]);
    rows.push(columns.join(","));

    for (const item of items) {
        let rowValues: Array<string> = [];
        for (const column of columns) {
            rowValues.push(`"${item[column].toString()}"`)
        }
        rows.push(rowValues.join(","))
    }

    return rows.join("\n")
}

export function downloadText(text: string, fileName: string): void {
    const blob = new Blob([text], {type: "octet/stream"});
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove()
}

export function downloadItems(items: Array<RowItem>): void {
    const csv = convertItemsToCsv(items);
    downloadText(csv, "result.csv")
}
