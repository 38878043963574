import {useAuth} from "../../../../services/auth";
import {ActionButton, Flex, Form, Link, Text, TextField, View} from "@adobe/react-spectrum"
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {isRequired, isValidEmail, passwordRules} from "../../../../utils/validation_rules";
import {FormErrorMessages, ErrorMessageWrapper} from "../../../components/FormErrorMessages";
import {StateErrorMessage} from "../../../../types";
import {CognitoUser} from "amazon-cognito-identity-js";

interface EmailFormState {
    email: string,
}

interface ForgottenPasswordFormState {
    verificationCode: string,
    password: string,
    passwordCopy: string,
}

enum Stage {
    SEND_CODE,
    CREATE_NEW_PASSWORD,
    SUCCESS,
}

function ForgottenPasswordForm() {
    const [errorMessage, setErrorMessage] = useState<StateErrorMessage>(null);
    const [stage, setStage] = useState<Stage>(Stage.SEND_CODE);
    const {forgotPasswordFlow, getUserPool} = useAuth();
    const [user, setUser] = useState<CognitoUser | null>(null);

    const {
        control: emailFormControl,
        formState: emailFormState,
        getValues: emailFormGetValues,
        trigger: emailFormTrigger,
    } = useForm<EmailFormState>({
        defaultValues: {
            email: "",
        },
        mode: "onChange",
    });

    const emailFormErrors = emailFormState.errors;

    const {
        control: passwordFormControl,
        formState: passwordFormState,
        getValues: passwordFormGetValues,
        trigger: passwordFormTrigger,
    } = useForm<ForgottenPasswordFormState>({
        defaultValues: {
            verificationCode: "",
            password: "",
            passwordCopy: "",
        },
        mode: "onChange",
    });

    const passwordFormErrors = passwordFormState.errors;


    const sendCode = async () => {
        const formIsValid = await emailFormTrigger()
        if (!formIsValid) {
            return;
        }
        const {email} = emailFormGetValues();
        const user = new CognitoUser({
            Username: email,
            Pool: getUserPool(),
        })

        forgotPasswordFlow.sendVerificationCode(user)
            .then((a) => {
                setStage(Stage.CREATE_NEW_PASSWORD);
                setUser(user);
            })
            .catch((error) => setErrorMessage(error.message))
    }

    const setNewPassword = async () => {
        const formIsValid = await passwordFormTrigger();
        if (!formIsValid) {
            return;
        }

        if (!user) {
            throw new Error("Sorry, something went wrong");
        }

        const {password, verificationCode} = passwordFormGetValues();
        forgotPasswordFlow.setNewPassword(user, verificationCode, password)
            .then(() => setStage(Stage.SUCCESS))
            .catch((error) => setErrorMessage(error.message));
    }

    return (
        <Flex width="100%" alignItems="center">
            {
                stage === Stage.SEND_CODE
                    ? (
                        <View width="100%">
                            <Form onSubmit={(e) => e.preventDefault()} width="100%">
                                <Controller
                                    name="email"
                                    control={emailFormControl}
                                    rules={{
                                        validate: {
                                            required: isRequired("Email"),
                                            email: isValidEmail
                                        },
                                    }}
                                    render={({field: {onChange, onBlur, value}}) => (
                                        <TextField
                                            UNSAFE_className={'white-label'}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            label="Email"
                                            autoFocus
                                        />
                                    )}
                                />
                                {
                                    emailFormState.touchedFields.email
                                        ? (
                                            <FormErrorMessages
                                                errors={emailFormErrors}
                                                name="email"
                                                style={{color: "black"}}
                                            />
                                        )
                                        : <></>
                                }

                                {
                                    errorMessage !== null
                                        ?
                                        (
                                            <ErrorMessageWrapper
                                                style={{color: "black"}}
                                                message={errorMessage}
                                            />
                                        )
                                        : <></>
                                }

                                <ActionButton
                                    marginTop="size-200"
                                    onPress={sendCode}
                                    type="button"
                                    isDisabled={!emailFormState.isValid}
                                    width="100%"
                                >
                                    Next
                                </ActionButton>
                            </Form>
                        </View>
                    )
                    : <></>
            }
            {
                stage === Stage.CREATE_NEW_PASSWORD
                    ? (
                        <Form onSubmit={(e) => e.preventDefault()} width="100%">
                            <Controller
                                name="verificationCode"
                                control={passwordFormControl}
                                rules={{
                                    validate: {
                                        required: isRequired("Verification code"),
                                    },
                                }}
                                render={({field: {onChange, onBlur, value}}) => (
                                    <TextField
                                        UNSAFE_className={'white-label'}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        label="Verification code sent to your email"
                                        autoFocus
                                    />
                                )}
                            />
                            {
                                passwordFormState.touchedFields.verificationCode
                                    ? (
                                        <FormErrorMessages
                                            errors={passwordFormState}
                                            name="verificationCode"
                                            style={{color: "black"}}
                                        />
                                    )
                                    : <></>
                            }

                            <Controller
                                name="password"
                                control={passwordFormControl}
                                rules={passwordRules}
                                render={({field: {onChange, onBlur, value}}) => (
                                    <TextField
                                        UNSAFE_className={'white-label'}
                                        type="password"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        label="Password"
                                        autoFocus
                                    />
                                )}
                            />
                            {
                                passwordFormState.touchedFields.password
                                    ? (
                                        <FormErrorMessages
                                            style={{color: "black"}}
                                            errors={passwordFormErrors}
                                            name="password"
                                        />
                                    )
                                    : <></>
                            }

                            <Controller
                                name="passwordCopy"
                                control={passwordFormControl}
                                rules={{
                                    validate: {
                                        required: isRequired("Password"),
                                        identical: (value) => {
                                            return value === passwordFormGetValues().password || "Passwords must be identical";
                                        },
                                    },
                                }}
                                render={({field: {onChange, onBlur, value}}) => (
                                    <TextField
                                        UNSAFE_className={'white-label'}
                                        type="password"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        label="Same password one more time"
                                        autoFocus
                                    />
                                )}
                            />
                            {
                                passwordFormState.touchedFields.passwordCopy
                                    ? (
                                        <FormErrorMessages
                                            style={{color: "black"}}
                                            errors={passwordFormErrors}
                                            name="passwordCopy"
                                        />
                                    )
                                    : <></>
                            }

                            {
                                errorMessage !== null
                                    ? (
                                        <ErrorMessageWrapper
                                            style={{color: "black"}}
                                            message={errorMessage}
                                        />
                                    )
                                    : <></>
                            }

                            <ActionButton
                                marginTop="size-200"
                                onPress={setNewPassword}
                                type="button"
                                isDisabled={!passwordFormState.isValid}
                                width="100%"
                            >
                                Set New Password
                            </ActionButton>
                        </Form>
                    )
                    : <></>
            }
            {
                stage === Stage.SUCCESS
                    ? (
                        <View>
                            <Text>
                                Your password has been successfully changed. <Link><a href="/">Log in</a></Link> now.
                            </Text>
                        </View>
                    )
                    : <></>
            }
        </Flex>

    );
}

export default ForgottenPasswordForm;