import {Heading, View} from "@adobe/react-spectrum";
import Select, {Props} from "react-select";
import {memo, useEffect} from "react";
import {
    deserializeOptionArray,
    getSearchParam,
    setOptionArraySearchParam,
} from "../../utils/url_search";
import {SearchParam, SelectOption} from "../../types";

export type MultiSelectOnChange = (options: Array<SelectOption>) => void

export interface MultiSelectProps extends Omit<Props, 'isMulti'> {
    searchParamName: string,
    label: string,
    options: Array<SelectOption>
    onChange: MultiSelectOnChange
}

function MultiSelect({
                         label,
                         onChange,
                         options,
                         searchParamName,
                         ...props
                     }: MultiSelectProps) {

    useEffect(() => {
        if (
            (options === undefined)
            || options.length === 0
        ) {
            return;
        }

        const optionNames = new Set(options.map((option) => {
            return option.value
        }))

        const optionsString = getSearchParam(searchParamName);
        if (optionsString !== null) {
            const optionsFromURL = deserializeOptionArray({
                value: optionsString,
                columns: optionNames,
            });

            onChange(optionsFromURL)
            setOptionArraySearchParam(
                optionsFromURL,
                "value",
                SearchParam.Columns,
            )
        }
    }, [
        options,
        onChange,
        searchParamName,
    ])

    const id = `multiselect${label}`;

    return (
        <View>
            <Heading
                id={id}
                level={4}
            >
                {label}
            </Heading>
            <Select
                {...props}
                aria-labelledby={id}
                isMulti
                options={options}
                onChange={(value) => {
                    const options = value as Array<SelectOption>;
                    onChange(options);
                    setOptionArraySearchParam(
                        options,
                        "value",
                        SearchParam.Columns,
                    );
                }}
            />
        </View>
    )
}

export default memo(MultiSelect);
