import {NavLink} from "react-router-dom";
import {Flex} from "@adobe/react-spectrum";
import "./styles.css"

export interface NavItemProps {
    pathname: string,
    isActivePattern?: string,
    children?: any,
}

function NavItem({
                     isActivePattern,
                     pathname,
                     children,
                 }: NavItemProps) {
    return (
        <NavLink
            exact={false}
            className="nav-item"
            activeClassName="nav-item--selected"
            isActive={(_, location) => (
                location.pathname.startsWith(isActivePattern || "")
            )}
            to={{ pathname }}>
            <Flex
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    gap="size-150"
                    UNSAFE_className="nav-item-inner"
                >
                    {children}
                </Flex>
            </Flex>
        </NavLink>
    )
}

export default NavItem;
