import {createContext, useContext} from "react";
import {AuthContext} from "./auth_context";

export const Context = createContext(AuthContext);

export function Provider({children}: any) {
    return (
        <Context.Provider
            value={AuthContext}
        >
            {children}
        </Context.Provider>
    )
}

export function useAuth() {
    return useContext(Context);
}