export function stringToDate(value: string): Date | null {
    const date = new Date(value);
    if (date.toString() === "Invalid Date") {
        return null
    }

    return date;
}

export function toISODate(inputDate: Date): string {
    const date = new Date(inputDate.valueOf())
    date.setMinutes(date.getMinutes() + ((-1) * date.getTimezoneOffset()))
    return date.toISOString().split("T")[0];
}
