import {useAuth} from "../../../../services/auth";
import {ActionButton, Flex, Form, TextField} from "@adobe/react-spectrum"
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {DEFAULT_ERROR_MESSAGE} from "../../../../constants";
import {isRequired, isValidEmail} from "../../../../utils/validation_rules";
import FormErrorMessages from "../../../components/FormErrorMessages";
import {ErrorMessageWrapper} from "../../../components/FormErrorMessages";
import {StateErrorMessage} from "../../../../types";
import {IndefiniteProgressCircle} from "../../../components/IndefiniteProgressCircle";
import {useMediaQuery} from "react-responsive";
import ConditionalDisplay from "../../../components/ConditionalDisplay";
import "./styles.css"

interface LoginFormState {
    email: string;
    password: string;
}

function LogInForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<StateErrorMessage>(null);
    const {authenticate} = useAuth();

    const isNarrowScreen = useMediaQuery({
        query: '(max-width: 870px)'
    });

    const {
        control,
        formState,
        getValues,
        trigger,
    } = useForm<LoginFormState>({
        defaultValues: {
            email: "",
            password: "",
        },
        mode: "onChange",
    });

    const errors = formState.errors;

    const login = async () => {
        const formIsValid = await trigger()
        if (!formIsValid) {
            return;
        }
        const {email, password} = getValues();
        setIsLoading(true);
        authenticate(email, password)
            .then(() => window.location.reload())
            .catch((error) => {
                switch (error.code) {
                    case "NotAuthorizedException":
                        return setErrorMessage(`${error.message}`);
                    default:
                        console.error(error);
                        return setErrorMessage(DEFAULT_ERROR_MESSAGE);
                }
            })
            .finally(() => setIsLoading(false));

    }

    return (
        <Flex
            width="100%"
        >
            <Form
                onSubmit={(e) => e.preventDefault()}
                width="100%"
                maxWidth={isNarrowScreen ? "100%" : "size-3600"}
            >
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        validate: {
                            required: isRequired("Email"),
                            email: isValidEmail
                        },
                    }}
                    render={({field: {onChange, onBlur, value}}) => (
                        <TextField
                            UNSAFE_className={'white-label'}
                            name="email"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            label="Email"
                            autoFocus
                        />
                    )}
                />
                {
                    formState.touchedFields.email
                        ? (
                            <FormErrorMessages
                                errors={errors}
                                name="email"
                                style={{color: "black"}}
                            />
                        )
                        : <></>
                }

                <Controller
                    name="password"
                    control={control}
                    rules={{
                        validate: {
                            required: isRequired("Password"),
                        }
                    }}
                    render={({field: {onChange, onBlur, value}}) => (
                        <TextField
                            UNSAFE_className={'white-label'}
                            name="password"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            label="Password"
                            type="password"
                        />
                    )}
                />
                {
                    formState.touchedFields.password
                        ? (
                            <FormErrorMessages
                                errors={errors}
                                name="password"
                                style={{color: "black"}}
                            />
                        )
                        : <></>
                }

                {
                    errorMessage !== null
                        ? (
                            <ErrorMessageWrapper
                                message={errorMessage}
                                style={{color: "black"}}
                            />
                        )
                        : <></>
                }

                <Flex
                    alignItems="center"
                    gap={"size-50"}
                    marginTop="size-200"
                >
                    <ActionButton
                        onPress={login}
                        type="button"
                        isDisabled={!formState.isValid || isLoading}
                        width="100%"
                    >
                        Log In
                    </ActionButton>

                    <ConditionalDisplay isHidden={!isLoading}>
                        <IndefiniteProgressCircle/>
                    </ConditionalDisplay>
                </Flex>
                <Flex justifyContent="center">
                    <a href="/forgotten-password"
                       style={{
                           color: "white",
                           textDecorationColor: "white",
                           fontSize: "1.1em",
                       }}
                    >
                        <span>Forgotten password?</span>
                    </a>
                </Flex>
            </Form>
        </Flex>

    );
}

export default LogInForm;
