import {Flex, View} from "@adobe/react-spectrum";
import {ViewProps} from "../../../types";
import GraphIcon from "../icons/GraphIcon";
import AwesomeAPILogo from "./AwesomeAPILogo";


export type LogoProps = ViewProps;

function AwesomeAPIAnalyticsLogo(props: LogoProps) {
    return (
        <View
            width="size-2000"
            {...props}
        >
            <Flex
                gap="size-150"
            >
                <AwesomeAPILogo/>
                <GraphIcon
                    width={30}
                />
            </Flex>
        </View>
    )
}

export default AwesomeAPIAnalyticsLogo;
