import {ActionButton, Text, View} from "@adobe/react-spectrum";
import ConditionalDisplay from "../../../../components/ConditionalDisplay";
import {IndefiniteProgressCircle} from "../../../../components/IndefiniteProgressCircle";
import React from "react";
import {FetchReport} from "./types";
import ContentHeader from "../../../../components/ContentHeader";
import AnalysisViewTypePicker, {AnalysisViewType, AnalysisViewTypePickerOnChange,} from "./AnalysisViewTypePicker";
import RunButton from "../../../../components/RunButton";
import {RowItem} from "../../../../../types";
import {downloadItems} from "../../../../../services/awesome-api/utils";
import DownloadIcon from "@spectrum-icons/workflow/Download";


export interface HeaderProps {
    isFetchingReport: boolean
    isReadyToFetchReport: boolean
    fetchReport: FetchReport
    tableData: Array<RowItem>
    analysisViewType: AnalysisViewType
    analysisViewTypePickerOnChange: AnalysisViewTypePickerOnChange
}

function Header({
                    fetchReport,
                    isFetchingReport,
                    isReadyToFetchReport,
                    tableData,
                    analysisViewType,
                    analysisViewTypePickerOnChange,
                }: HeaderProps) {
    return (
        <ContentHeader>
            <RunButton
                marginStart="size-50"
                isDisabled={!isReadyToFetchReport}
                onPress={() => fetchReport()}
            />

            <ConditionalDisplay isHidden={!isFetchingReport}>
                <IndefiniteProgressCircle
                    marginStart="size-400"
                />
            </ConditionalDisplay>

            <AnalysisViewTypePicker
                marginStart="size-400"
                value={analysisViewType}
                onChange={analysisViewTypePickerOnChange}
            />

            <View
                marginStart="auto"
            >
                <ActionButton
                    isDisabled={tableData.length === 0}
                    onPress={() => downloadItems(tableData)}
                >
                    <DownloadIcon/>
                    <Text>Download</Text>
                </ActionButton>
            </View>

        </ContentHeader>
    )
}

export default Header;
