import {IconProps} from "./IconProps";


function DownloadIcon({
                          width,
                      }: IconProps) {
    return (
        <svg
            viewBox="0 0 512 512"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z"/>
                <path
                    d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z"/>
            </g>
        </svg>
    )
}

export default DownloadIcon;
