import {Flex, Heading} from "@adobe/react-spectrum";

function NoData() {
    return (
        <Flex
            alignContent="center"
            justifyContent="center"
        >
            <Heading
                level={1}
            >
                No Data
            </Heading>
        </Flex>
    )
}

export default NoData;
