import {Text, View} from "@adobe/react-spectrum";
import DownloadIcon from "../../components/icons/DownloadIcon";
import {Icon} from "../../components/icons/IconProps";
import NavItem, {NavItemProps} from "./NavItem/NavItem";
import BarChartIcon from "../../components/icons/BarChartIcon";
import {useMemo} from "react";


interface MenuItem extends Omit<NavItemProps, 'children'> {
    name: string,
    Icon: Icon,
}

function Sidebar() {
    const menuItems: Array<MenuItem> = useMemo(() => [
        {
            pathname: "/data",
            name: "Data",
            isActivePattern: "/data",
            Icon: DownloadIcon,
        },
        {
            pathname: "/analysis",
            name: "Analysis",
            isActivePattern: "/analysis",
            Icon: BarChartIcon,
        }
    ], [])

    return (
        <View
            gridArea="sidebar"
        >
            <View
                elementType="nav"
                marginY="size-450"
            >
                {
                    menuItems.map(({
                                       Icon,
                                       name,
                                       pathname,
                                       isActivePattern,
                                   }) => (
                        <NavItem
                            key={name}
                            pathname={pathname}
                            isActivePattern={isActivePattern}
                        >
                            <Icon width={20}/>
                            <Text>{name}</Text>
                        </NavItem>
                    ))
                }
            </View>
        </View>
    )
}

export default Sidebar;
