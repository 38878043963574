import {Heading, Item, Picker, View} from "@adobe/react-spectrum";
import {memo, useEffect} from "react";
import {getSearchParam, setSearchParam} from "../../utils/url_search";
import {PickerOption, SearchParam, TimeInterval} from "../../types";
import {SpectrumPickerProps} from "@react-types/select";

export const intervals: Array<PickerOption> = Object.values(TimeInterval)
    .map((interval) => ({
        id: interval,
        name: interval,
    }));

export type IntervalPickerOnChange = (interval: TimeInterval) => void

export interface IntervalPickerProps extends Omit<SpectrumPickerProps<PickerOption>, "children"> {
    searchParamName?: SearchParam,
    onChange: IntervalPickerOnChange
    value: TimeInterval,
}

function isTimeInterval(value: string): value is TimeInterval {
    const optionNames: Set<string> = new Set(Object.values(TimeInterval));
    return optionNames.has(value);
}

function IntervalPicker({
                            onChange,
                            searchParamName = SearchParam.TimeInterval,
                            label = "Interval",
                            value,
                            ...props
                        }: IntervalPickerProps) {

    useEffect(() => {
        const optionString = getSearchParam(searchParamName);
        if (
            (optionString !== null)
            && isTimeInterval(optionString)
        ) {
            onChange(optionString)
        } else {
            setSearchParam(searchParamName, TimeInterval.Day)
        }
    }, [
        onChange,
        searchParamName,
    ])

    const id = `intervalPicker${label}`;

    return (
        <View>
            <Heading
                id={id}
                level={4}
            >
                {label}
            </Heading>
            <Picker
                aria-labelledby={id}
                items={intervals}
                selectedKey={value}
                onSelectionChange={(value) => {
                    const interval = value as TimeInterval;
                    onChange(interval);
                    setSearchParam(searchParamName, interval)
                }}
                {...props}
            >
                {(item) => <Item>{item.name}</Item>}
            </Picker>
        </View>
    )
}

export default memo(IntervalPicker);
