import React, {useCallback, useEffect, useMemo, useReducer} from "react";

import {Flex, View} from "@adobe/react-spectrum";

import "../../../components/Table/table_styles.css"
import {FiltersPropsOnChange} from "../../../components/Filters";
import {MILLISECONDS_IN_MONTH} from "../../../../constants";
import {useGetColumns, useGetItems} from "../../../../services/awesome-api";
import {ActionType, initialState, reducer, State} from "./State";
import Sidebar from "./Sidebar";
import Main from "./Main/Main";
import {DateRangePickerOnChange} from "../../../components/DateRangePicker";
import {MultiSelectOnChange} from "../../../components/MultiSelect";
import {AnalysisEntityPickerOnChange} from "../../../components/AnalysisEntityPicker";
import {useAuth} from "../../../../services/auth";
import {toISODate} from "../../../../utils/date";

function DataScreen() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        useGetIdToken,
    } = useAuth();

    const token = useGetIdToken() || '';

    const {
        data: columns,
        error: getColumnsError,
        isFetching: isFetchingColumns,
        refetch: fetchColumns,
    } = useGetColumns({
        entity: state.analysisEntity,
        token,
    })

    useEffect(() => {
        if (token !== '') {
            fetchColumns()
        }
    }, [
        fetchColumns,
        token,
    ])

    const queryRules = useMemo(() => {
        return state.ruleGroup.rules.map((rule) => ({
            value: rule.expression,
            operator: rule.operator,
            field: rule.field.name,
            //    TODO factor out. See the same in AnalyticsScreen
        })).concat([
            {
                value: toISODate(state.dateRange.startDate),
                operator: "greater than or equal to",
                field: "date",
            }
        ]).concat([
            {
                value: toISODate(state.dateRange.endDate),
                operator: "less than or equal to",
                field: "date",
            }
        ])
    }, [
        state.dateRange,
        state.ruleGroup.rules,
    ]);

    const {
        data: leadResponse,
        error: getLeadsError,
        isFetching: isFetchingFirstPageOfLeads,
        refetch: fetchLeads,
    } = useGetItems({
        columns: state.selectedColumns.map((column) => column.value),
        rule_group: {
            rules: queryRules
        },
        entity: state.analysisEntity,
        token,
    })

    useEffect(() => {
        // Calculate isReadyToFetchLeads
        let isReadyToFetchLeads = true;
        const reasons: State["isNotReadyToFetchLeadsReasons"] = [];

        if (token === '') {
            isReadyToFetchLeads = false;
        } else if (isFetchingColumns || isFetchingFirstPageOfLeads) {
            isReadyToFetchLeads = false;
        } else if (state.selectedColumns.length === 0) {
            isReadyToFetchLeads = false;
            reasons.push("Select at least one column to execute query.")
        }

        const range = state.dateRange;
        const selectedInterval = range.endDate.valueOf() - range.startDate.valueOf();
        if (selectedInterval > MILLISECONDS_IN_MONTH) {
            isReadyToFetchLeads = false;
            reasons.push("The maximum data range is 1 month. Please, shorten the range.")
        }

        dispatch({
            type: ActionType.SET_IS_READY_TO_FETCH_LEADS,
            payload: {
                isReadyToFetchLeads,
                reasons,
            }
        })
    }, [
        isFetchingColumns,
        isFetchingFirstPageOfLeads,
        state.ruleGroup.rules,
        state.selectedColumns,
        state.dateRange,
        token,
    ]);

    const dateRangePickerOnChange: DateRangePickerOnChange = useCallback((dateRange) => {
        dispatch({
            type: ActionType.SET_DATE_RANGE,
            payload: {dateRange},
        })
    }, [])

    const filtersPropsOnChange: FiltersPropsOnChange = useCallback((rules) => {
        dispatch({
            type: ActionType.SET_RULE_GROUP,
            payload: {ruleGroup: {rules}}
        })
    }, [])

    const columnsOnChange: MultiSelectOnChange = useCallback((columns) => {
        dispatch({
            type: ActionType.SET_SELECTED_COLUMNS,
            payload: {columns}
        })
    }, [])

    const analysisEntityPickerOnChange: AnalysisEntityPickerOnChange = useCallback((entity) => {
        dispatch({
            type: ActionType.SET_ANALYSIS_ENTITY,
            payload: {entity}
        })
    }, [])

    return (
        <View
            UNSAFE_className="unscrollable"
        >
            <Flex
                direction="column"
                UNSAFE_className="unscrollable"
            >

                <Flex
                    gap="size-300"
                    UNSAFE_className="unscrollable"
                >
                    <Sidebar
                        isFetchingColumns={isFetchingColumns}
                        getColumnsError={getColumnsError}
                        columns={columns}
                        dateRangePickerOnChange={dateRangePickerOnChange}
                        filtersPropsOnChange={filtersPropsOnChange}
                        columnsOnChange={columnsOnChange}
                        dateRange={state.dateRange}
                        selectedColumns={state.selectedColumns}
                        filterRules={state.ruleGroup.rules}
                        analysisEntity={state.analysisEntity}
                        analysisEntityPickerOnChange={analysisEntityPickerOnChange}
                    />

                    <Main
                        getLeadsError={getLeadsError}
                        isFetchingFirstPageOfLeads={isFetchingFirstPageOfLeads}
                        fetchLeads={fetchLeads}
                        leadResponse={leadResponse}
                        state={state}
                    />

                </Flex>
            </Flex>
        </View>
    )
}

export default DataScreen
