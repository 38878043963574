import React from "react";
import {View} from "@adobe/react-spectrum";
import {Redirect, Route, Switch} from "react-router-dom";
import DataScreen from "../../screens/content/DataScreen/DataScreen";
import AnalysisScreen from "../../screens/content/AnalysisScreen/AnalysisScreen";


function Content() {
    return (
        <View
            gridArea="content"
            padding="size-150"
            UNSAFE_className="unscrollable"
        >
            <Switch>
                <Route path="/analysis">
                    <AnalysisScreen/>
                </Route>
                <Route path="/data">
                    <DataScreen/>
                </Route>
                <Route path="/">
                    <Redirect to="/data"/>
                </Route>
            </Switch>
        </View>
    )
}

export default Content