export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID || "";
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID || "";
export const AWS_REGION = process.env.REACT_APP_AWS_REGION || "us-east-1";
export const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID || "";
export const LEAD_RESULTS_BUCKET = process.env.REACT_APP_LEAD_RESULTS_BUCKET || "";


export const EMAIL_RE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const LOWERCASE_RE = /[a-z]/;
export const UPPERCASE_RE = /[A-Z]/;
export const DIGITS_RE = /\d/;
export const DEFAULT_ERROR_MESSAGE = "Sorry, there was an error";
export const MILLISECONDS_IN_WEEK = 1000 * 60 * 60 * 24 * 7;
export const MILLISECONDS_IN_MONTH = 1000 * 60 * 60 * 24 * 32;
export const YESTERDAY = new Date(new Date().setDate(new Date().getDate() - 1));
export const TODAY = new Date();
