import {useQuery} from "react-query";
import {convertArraysToObjects} from "./utils";
import {AnalysisEntity, RowItem, TimeInterval} from "../../types";

const baseURL = process.env.REACT_APP_API_URL;

export interface Column {
    name: string,
    comment: string | null,
}

export interface getColumnsRequest {
    entity: AnalysisEntity,
    token: string,
}

export async function getColumns(request: getColumnsRequest): Promise<Array<Column>> {
    const response = await fetch(`${baseURL}/analytics/entity/${request.entity}/columns`, {
        headers: {
            'Authorization': request.token,
        }
    })
    if (!response.ok) {
        throw new Error(response.statusText)
    }
    return response.json()
}


export function useGetColumns(request: getColumnsRequest) {
    return useQuery(
        `column-names-${request.entity}`,
        () => getColumns(request),
        {
            staleTime: Infinity,
            enabled: false,
        })
}

export interface Rule {
    field: string
    operator: string
    value: string
}

export interface GetItemsRequest {
    columns: Array<string>,
    rule_group: { rules: Array<Rule> },
    entity: AnalysisEntity,
    token: string,
}

export type ColumnValues = { [columnName: string]: Array<string | number> }

export interface GetItemsResponse {
    items: ColumnValues
    total: number
}


export interface FormattedQueryResponse {
    items: Array<RowItem>
    total: number
}

export async function getItems(request: GetItemsRequest): Promise<GetItemsResponse> {
    const response = await fetch(`${baseURL}/analytics/entity/${request.entity}/data`, {
        method: "POST",
        body: JSON.stringify({
            columns: request.columns,
            rule_group: request.rule_group,
        }),
        headers: {
            'Authorization': request.token,
            'Content-Type': 'application/json',
        }
    })
    if (!response.ok) {
        throw new Error(response.statusText)
    }
    return response.json();
}

export function useGetItems(request: GetItemsRequest) {
    return useQuery<FormattedQueryResponse>(
        ['leads', request],
        () => getItems(request)
            .then((data) => {
                return {
                    items: convertArraysToObjects(data.items, data.total),
                    total: data.total,
                }
            }),
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
        })
}


export interface GetAnalysisReportRequest {
    columns: Array<string>,
    rule_group: { rules: Array<Rule> },
    interval: TimeInterval,
    entity: AnalysisEntity,
    token: string,
}


export async function getAnalysisReport(request: GetAnalysisReportRequest): Promise<GetItemsResponse> {
    const response = await fetch(`${baseURL}/analytics/entity/${request.entity}/analytics`, {
        method: "POST",
        body: JSON.stringify({
            rule_group: request.rule_group,
            interval: request.interval,
            entity: request.entity,
            columns: request.columns,
        }),
        headers: {
            'Authorization': request.token,
            'Content-Type': 'application/json',
        }
    })

    if (!response.ok) {
        throw new Error(response.statusText)
    }
    return response.json();
}

// These columns will always be present for analysis
export enum AnalyticsColumn {
    TimeStamp = 'ts',
    Value = 'value',
}

export function useGetAnalysisReport(request: GetAnalysisReportRequest) {
    return useQuery<FormattedQueryResponse>(
        ['analysis', request],
        () => getAnalysisReport(request)
            .then((data) => {
                return {
                    total: data.total,
                    items: convertArraysToObjects(
                        data.items,
                        data.total,
                        [
                            ...request.columns,
                            ...[AnalyticsColumn.TimeStamp, AnalyticsColumn.Value]
                        ]
                    ),
                }
            }),
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
        })
}
