import {
    DateRangePicker as OriginalDateRangePicker,
    DateRangePickerProps as OriginalDateRangePickerProps,
    RangeWithKey,
} from "react-date-range";
import {ActionButton, Content, Dialog, DialogTrigger, Heading, Text, View} from "@adobe/react-spectrum";
import {DATE_RANGE_KEY, DATE_RANGE_MIN_DATE} from "../screens/content/DataScreen/constants";
import {TODAY, YESTERDAY} from "../../constants";
import React, {useEffect} from "react";
import {stringToDate, toISODate} from "../../utils/date";
import {getSearchParam, setSearchParam} from "../../utils/url_search";
import {SearchParam} from "../../types";

export type DateRange = Required<Pick<RangeWithKey, 'startDate' | 'endDate' | 'key'>>;

export function setDateRangeParam(dateRange: DateRange) {
    const value = `${toISODate(dateRange.startDate)},${toISODate(dateRange.endDate)}`;
    setSearchParam(SearchParam.DataRange, value)
}

export type DateRangePickerOnChange = (dateRange: DateRange) => void;

export interface DateRangePickerProps extends Omit<OriginalDateRangePickerProps, 'onChange'> {
    dateRange: DateRange,
    onChange: (dateRange: DateRange) => void,
}

export type DateRangeFromURL = Omit<DateRange, 'key'>

export const defaultDateRange: DateRange = {
    startDate: YESTERDAY,
    endDate: TODAY,
    key: DATE_RANGE_KEY,
}

export function getDateRangeSearchParam(): DateRangeFromURL | null {
    const dateRange = getSearchParam(SearchParam.DataRange);

    if (dateRange !== null) {
        const [startDateString, endDateString] = dateRange.split(",");
        if (startDateString && endDateString) {
            const startDate = stringToDate(startDateString);
            const endDate = stringToDate(endDateString);
            if (
                startDate !== null
                && (startDate.valueOf() >= DATE_RANGE_MIN_DATE.valueOf())
                && endDate !== null
                && (endDate.valueOf() <= TODAY.valueOf())
                && (startDate.valueOf() < endDate.valueOf())
            ) {
                return {
                    startDate,
                    endDate,
                }
            }
        }
    }

    return null;
}

function DateRangePicker({
                                    minDate = DATE_RANGE_MIN_DATE,
                                    maxDate = TODAY,
                                    dateRange,
                                    onChange,
                                }: DateRangePickerProps) {

    const selectedDateRangeString = `${dateRange.startDate.toDateString()} - ${dateRange.endDate.toDateString()}`

    useEffect(() => {
        // Process url search params that do not require any data
        // to be downloaded beforehand
        const dateRange = getDateRangeSearchParam();
        if (dateRange !== null) {
            const dateRangeToSet = {
                ...dateRange,
                key: DATE_RANGE_KEY,
            }
            onChange(dateRangeToSet)
            // setDateRangeParam(dateRangeToSet)
        } else {
            setDateRangeParam(defaultDateRange)
        }
    }, [
        onChange,
    ])

    return (
        <View>
            <Heading level={4}>Date range</Heading>

            <View
                paddingX="size-50"
            >
                <DialogTrigger type="popover">
                    <ActionButton
                        width="100%"
                    >
                        <Text>
                            {selectedDateRangeString}
                        </Text>
                    </ActionButton>
                    <Dialog
                        width="680px"
                    >
                        <Content>
                            <OriginalDateRangePicker
                                minDate={minDate}
                                maxDate={maxDate}
                                ranges={[dateRange]}
                                onChange={(range) => {
                                    // @ts-ignore
                                    const dateRange = range.selection! as DateRange;

                                    onChange(dateRange)
                                    setDateRangeParam(dateRange)
                                }}
                            />
                        </Content>
                    </Dialog>
                </DialogTrigger>
            </View>
        </View>
    )
}

export default React.memo(DateRangePicker);
