import {IconProps} from "./IconProps";

function BarChartIcon({
                       fill = "white",
                       width,
                   }: IconProps) {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            width={width}
        >
            <path d="M119.5,187.75H17.1c-9.4,0-17,7.6-17.1,17.1v256c0,9.5,7.7,17.1,17.1,17.1h102.4c9.5,0,17.1-7.7,17.1-17.1v-256
			C136.6,195.35,128.9,187.75,119.5,187.75z"/>
            <path d="M290.2,0.05H187.8c-9.4,0-17.1,7.6-17.1,17v443.8c0,9.5,7.7,17.1,17.1,17.1h102.4c9.5,0,17.1-7.7,17.1-17.1V17.15
			C307.3,7.65,299.6,0.05,290.2,0.05z"/>
            <path d="M460.9,136.55H358.5c-9.5,0-17.1,7.6-17.1,17.1v307.2c0,9.5,7.7,17.1,17.1,17.1h102.4c9.5,0,17.1-7.7,17.1-17.1v-307.2
			C478,144.15,470.3,136.55,460.9,136.55z"/>
        </svg>
    )
}


export default BarChartIcon;